// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-templates-404-js": () => import("./../../../src/templates/404.js" /* webpackChunkName: "component---src-templates-404-js" */),
  "component---src-templates-about-clients-template-js": () => import("./../../../src/templates/about-clients-template.js" /* webpackChunkName: "component---src-templates-about-clients-template-js" */),
  "component---src-templates-about-company-template-js": () => import("./../../../src/templates/about-company-template.js" /* webpackChunkName: "component---src-templates-about-company-template-js" */),
  "component---src-templates-big-data-template-js": () => import("./../../../src/templates/big-data-template.js" /* webpackChunkName: "component---src-templates-big-data-template-js" */),
  "component---src-templates-blog-template-js": () => import("./../../../src/templates/blog-template.js" /* webpackChunkName: "component---src-templates-blog-template-js" */),
  "component---src-templates-careers-template-js": () => import("./../../../src/templates/careers-template.js" /* webpackChunkName: "component---src-templates-careers-template-js" */),
  "component---src-templates-category-template-js": () => import("./../../../src/templates/category-template.js" /* webpackChunkName: "component---src-templates-category-template-js" */),
  "component---src-templates-cloud-development-and-migration-template-js": () => import("./../../../src/templates/cloud-development-and-migration-template.js" /* webpackChunkName: "component---src-templates-cloud-development-and-migration-template-js" */),
  "component---src-templates-contact-us-template-js": () => import("./../../../src/templates/contactUs-template.js" /* webpackChunkName: "component---src-templates-contact-us-template-js" */),
  "component---src-templates-cookies-policy-template-js": () => import("./../../../src/templates/cookies-policy-template.js" /* webpackChunkName: "component---src-templates-cookies-policy-template-js" */),
  "component---src-templates-devops-template-js": () => import("./../../../src/templates/devops-template.js" /* webpackChunkName: "component---src-templates-devops-template-js" */),
  "component---src-templates-google-cloud-data-fusion-template-js": () => import("./../../../src/templates/google-cloud-data-fusion-template.js" /* webpackChunkName: "component---src-templates-google-cloud-data-fusion-template-js" */),
  "component---src-templates-google-cloud-platform-template-js": () => import("./../../../src/templates/google-cloud-platform-template.js" /* webpackChunkName: "component---src-templates-google-cloud-platform-template-js" */),
  "component---src-templates-index-template-js": () => import("./../../../src/templates/index-template.js" /* webpackChunkName: "component---src-templates-index-template-js" */),
  "component---src-templates-iot-development-template-js": () => import("./../../../src/templates/iot-development-template.js" /* webpackChunkName: "component---src-templates-iot-development-template-js" */),
  "component---src-templates-it-managed-services-template-js": () => import("./../../../src/templates/it-managed-services-template.js" /* webpackChunkName: "component---src-templates-it-managed-services-template-js" */),
  "component---src-templates-mtgc-template-js": () => import("./../../../src/templates/mtgc-template.js" /* webpackChunkName: "component---src-templates-mtgc-template-js" */),
  "component---src-templates-post-template-js": () => import("./../../../src/templates/post-template.js" /* webpackChunkName: "component---src-templates-post-template-js" */),
  "component---src-templates-privacy-policy-template-js": () => import("./../../../src/templates/privacy-policy-template.js" /* webpackChunkName: "component---src-templates-privacy-policy-template-js" */),
  "component---src-templates-services-cdap-template-js": () => import("./../../../src/templates/services-cdap-template.js" /* webpackChunkName: "component---src-templates-services-cdap-template-js" */),
  "component---src-templates-services-custom-software-development-template-js": () => import("./../../../src/templates/services-custom-software-development-template.js" /* webpackChunkName: "component---src-templates-services-custom-software-development-template-js" */),
  "component---src-templates-services-software-development-js": () => import("./../../../src/templates/services-software-development.js" /* webpackChunkName: "component---src-templates-services-software-development-js" */),
  "component---src-templates-services-technical-support-template-js": () => import("./../../../src/templates/services-technical-support-template.js" /* webpackChunkName: "component---src-templates-services-technical-support-template-js" */),
  "component---src-templates-tag-template-js": () => import("./../../../src/templates/tag-template.js" /* webpackChunkName: "component---src-templates-tag-template-js" */),
  "component---src-templates-terms-of-use-template-js": () => import("./../../../src/templates/terms-of-use-template.js" /* webpackChunkName: "component---src-templates-terms-of-use-template-js" */),
  "component---src-templates-thank-you-template-js": () => import("./../../../src/templates/thankYou-template.js" /* webpackChunkName: "component---src-templates-thank-you-template-js" */)
}

